<template>
  <div>
    <TableRittenRendabiliteitTemplate title="Rendabiliteit" :facturatie-id="facturatieId" />
  </div>
</template>

<script setup>
import { defineAsyncComponent, defineProps } from 'vue'

const TableRittenRendabiliteitTemplate = defineAsyncComponent(() => import('@/components/Table/Ritten/RendabiliteitTemplate.vue'))

defineProps({
  facturatieId: Number,
})
</script>

